
import { Component, Vue } from "vue-property-decorator";
import { readAdminWasteSamples } from "@/store/admin/getters";
import {
  dispatchExportAllWasteSamples,
  dispatchGetWasteSamples,
} from "@/store/admin/actions";

@Component
export default class AdminWasteSamples extends Vue {
  public headers = [
    {
      text: "Waste Level",
      sortable: true,
      value: "waste_level",
      align: "left",
    },
    {
      text: "Latitude",
      sortable: true,
      value: "latitude",
      align: "left",
    },
    {
      text: "Longitude",
      sortable: true,
      value: "longitude",
      align: "left",
    },
    {
      text: "Sampling Date",
      sortable: true,
      value: "sampling_date",
      align: "left",
    },
  ];
  get waste_samples() {
    return readAdminWasteSamples(this.$store);
  }

  public async mounted() {
    await dispatchGetWasteSamples(this.$store);
  }

  public async exportWasteSamplesPressed() {
    await dispatchExportAllWasteSamples(this.$store);
  }
}
